import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaXing,
  FaGithub,
  FaYoutube,
  FaTwitter
} from "react-icons/fa";
import { SiPolywork } from "react-icons/si";


const Footer = () => {
  return (
    <div className="footer">
      <ul className="footer-social">
        <a
          href="https://www.facebook.com/anes.mulalic"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://twitter.com/AnesMulalic"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaTwitter/>
        </a>
        <a
          href="https://www.instagram.com/anes_mulalic"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaInstagram />
        </a>
        <a
          href="https://github.com/anesask"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaGithub />
        </a>
        <a
          href="https://www.polywork.com/mulalic"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <SiPolywork />
        </a>
        <a
          href="https://www.linkedin.com/in/anesmulalic/"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaLinkedinIn />
        </a>

        <a
          href="https://www.xing.com/profile/Anes_Mulalic"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaXing />
        </a>
        <a
          href="https://www.youtube.com/channel/UCjRZZb1xR4jtKmRljq4d3JQ/videos"
          target="_blank"
          rel="noreferrer"
          className="footer-social--icon"
        >
          <FaYoutube />
        </a>
      </ul>
    </div>
  );
};

export default Footer;
