import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const About = () => {
  return (
    <section className="about" id="about">
      <div className="section-title">
        <h2 className="size-1">About Me</h2>
      </div>
      <div className="container grid">
        <div className="about-text">
          <p className="gradient-text">
            Hi, I’m Anes Mulalic, a freelance web developer with a passion for
            crafting clean, dynamic web applications. I was raised in Moscow,
            Russia and Bosnia and Herzegovina, but am currently based in
            Dieburg, Germany with family.
          </p>
          <p>
            I'm a former Computer Science teacher and for last decade
            <br />
            I’m completely dedicated to learning and developing web
            applications.
          </p>
          <p>
            Freelancing as a Web Developer on different online platforms has
            given me the opportunity to work on various web projects and build a
            diverse portfolio to include personal and business web apps, from
            layout to function according to the client’s specifications.
          </p>
          <p>
            I studied computer science and am currently working on my Master
            Thesis.{" "}
          </p>
          <p>
            My ultimate goal is to create and grow (bootstrap) a SaaS company.{" "}
          </p>
          <p>
            When I’m not coding, I also love design, photography and music. I
            also enjoy working on personal ideas and shipping side projects in
            free time.{" "}
          </p>
        </div>
        <div className="about-image-wrapper">
          <StaticImage
            alt="Anes Mulalic, Web Developer"
            src="../images/aboutme.jpg"
            className="about-image"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
