import React from "react";
import { StaticImage } from "gatsby-plugin-image";
// import { FaChevronDown } from "react-icons/fa";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container grid">
        <div className="hero-text">
          <h1>
            Where <span className="gradient-text">Design</span> intersect Code
          </h1>
          <h2>
            Fullstack JavaScript{" "}
            <span className="gradient-text-bg">Developer</span>
            <br />
            from Di-Da , Germany
          </h2>
          {/* <Link to="/#about">
            <FaChevronDown />
          </Link> */}
        </div>
        <div className="hero-image-wrapper">
          <StaticImage
            alt="Anes Mulalic, Web Developer"
            src="../images/face.png"
            className="hero-image"
            placeholder="blurred"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
