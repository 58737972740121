import React from "react";
import "../styles/main.scss";

import Layout from "../components/Layout";
import About from "../components/About";
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const IndexPage = () => {
  
  return (
    <Layout>
      <Hero />
      <About />
      <Contact />
      <Footer/>
    </Layout>
  );
};

export default IndexPage;
