import React, { useState } from "react";
import { navigate } from "gatsby";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() => navigate("/success/"))
      // .catch((error) => console.log(error));
  }

  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="section-title">
          <h2 className="size-1">Contact</h2>
        </div>
        <div className="section-wrapper">
          <form
            name="contact"
            action="/success/"
            className="contact-form"
            netlify
            data-netlify="true"
            netlify-honeypot="bot-field"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <textarea
                name="message"
                cols="30"
                rows="10"
                className="form-control"
                placeholder="Your Message"
                required
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn btn-submit"
              value="Send Message"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
